// raw: recorded directly in game sim
// derived: still stored in database, but not directly recorded in game sim
// not present in this file: transiently derived things, like FG%
const stats = {
	derived: ["av", "qbW", "qbL", "qbT", "qbOTL"] as const,
	raw: [
		"gp",
		"gs",
		"min",
		"fmb",
		"fmbLost",
		"pssCmp",
		"pss",
		"pssYds",
		"pssTD",
		"pssInt",
		"pssLng",
		"pssSk",
		"pssSkYds",
		"rus",
		"rusYds",
		"rusTD",
		"rusLng",
		"tgt",
		"rec",
		"recYds",
		"recTD",
		"recLng",
		"pr",
		"prYds",
		"prTD",
		"prLng",
		"kr",
		"krYds",
		"krTD",
		"krLng",
		"defInt",
		"defIntYds",
		"defIntTD",
		"defIntLng",
		"defPssDef",
		"defFmbFrc",
		"defFmbRec",
		"defFmbYds",
		"defFmbTD",
		"defFmbLng",
		"defSk",
		"defTckSolo",
		"defTckAst",
		"defTckLoss",
		"defSft",
		"fg0",
		"fga0",
		"fg20",
		"fga20",
		"fg30",
		"fga30",
		"fg40",
		"fga40",
		"fg50",
		"fga50",
		"fgLng",
		"xp",
		"xpa",
		"pnt",
		"pntYds",
		"pntLng",
		"pntTB",
		"pntIn20",
		"pntBlk",
		"pen",
		"penYds",
	] as const,
	max: [] as const,
};

export default stats;
